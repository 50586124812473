.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(./images/NFT-background.jpg);
  height: 100%;
  min-height: 460px;
  width: 100%;
  color: white;
  padding-top: 50px;
  box-sizing: border-box;
  padding-bottom: 50px;
  background-position: 50%;
  background-size: 600px 900px;
}

.App-link {
  color: #61dafb;
}

section {
  padding: 30px 20px;
}

.team-section {
  background: #3B3B98;
  color: white;
}

.team-member img {
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
}

footer {
  background: #2c2c54;
  color: white;
  padding: 50px 20px;
}

/***/

@media screen and (min-width: 900px) {
  .App-header {
    height: 100vh !important;
  }
}

.App-logo {
  height: 30vmin;
  border-radius: 10px;
  pointer-events: none;
}

.title-wallet {
  font-size: 30px;
  font-family: Boogaloo;
}

.title-wallet-sub {
  font-size: 18px;
}

.title-wallet-bold {
  font-size: 24px;
  font-weight: bold;
}

.wallet-selection {
  border: 3px solid white;
  border-radius: 4px;
  background-color: black;
  width: 100%;
  margin: 0 auto;
}

.countdown-area {
    padding: 16px;
    background: #b71540;
    margin: 16px;
    border-radius: 4px;
    display: block;
}

.countdown-area Grid Grid {
  font-size: 16px;
}

.countdown-area Grid Grid span {
  font-size: 12px;
}

.question-gif {
  width: 50%;
  max-width: 200px;
  border: 3px solid white;
  border-radius: 4px;
  margin: 0 auto;
}


.faq-section {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(./images/NFT-background.jpg);
  background-position: 50%;
  background-size: 600px 900px;
}
